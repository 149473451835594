<template>
    <div>
        <div
             :class="className"
             :data-textile-id="dataTextileId"
             :data-color-id="dataColorId"
             :data-textile="dataTextile"
             :data-color="dataColor"


             :modal-title="modalTitle"
             :data-ratio="dataRatio"
             :data-price="dataPrice"
        >

            <button
                    @click="showModal"
                    type="button"
                    id="new_arrival"
                    data-action=""
                    class="btn btn-primary">Новое поступление</button>

        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },

        props: {
            className: String,
            dataTextileId: Number,
            dataColorId: Number,
            dataTextile: String,
            dataColor: String,
            modalTitle: String,
            dataRatio: Number,
            dataPrice: Number,
        },

        methods: {
            showModal() {
                this.$store.state.priceData = { ...this.$props};

                let modal = $('#textileStoragePriceModal');
                modal.modal('show');
            },
        },
    }
</script>

<style scoped>

</style>