$( document ).ready(function() {


/*
    $('.textile-storage-update-price').on('click', function (e) {

        //console.log($(this)[0].dataset);

        var input_id = $(this).attr('id');
        var textile_id = $(this).attr('data-textile_id');
        var color_id = $(this).attr('data-color_id');
        var textile = $(this).attr('data-textile');
        var color = $(this).attr('data-color');
        var unit = $(this).attr('data-unit');
//console.log(unit);
        var modal = $('#textileStoragePriceModal');

        modal.find('#input_id').val(input_id);
        modal.find('#textile_id').val(textile_id);
        modal.find('#color_id').val(color_id);
        modal.find('#textile').val(textile);
        modal.find('#color').val(color);
        modal.find('#unit').val(unit);

        var info = textile + ', ' + color;

        modal.find('#modal-textile-info').html(info);

        modal.modal('show');
    });

*/
});
