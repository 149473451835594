var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        class: _vm.className,
        attrs: {
          "data-textile-id": _vm.dataTextileId,
          "data-color-id": _vm.dataColorId,
          "data-textile": _vm.dataTextile,
          "data-color": _vm.dataColor,
          "modal-title": _vm.modalTitle,
          "data-ratio": _vm.dataRatio,
          "data-price": _vm.dataPrice
        }
      },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button", id: "new_arrival", "data-action": "" },
            on: { click: _vm.showModal }
          },
          [_vm._v("Новое поступление")]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }