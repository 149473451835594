<template>
    <div class="modal fade" ref="storagePriceModal" id="storagePriceModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">

                <div class="modal-header">
                    <h4 class="modal-title" id="modal-label">{{ this.$store.state.priceData.modalTitle }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>

                <div class="modal-body">
                    <div class="m-y-1" id="modal-textile-info">
                        {{ this.$store.state.priceData.dataTextile }},
                        {{ this.$store.state.priceData.dataColor }}
                    </div>

                    <div>
                        <div>
                            <label for="count2" class="control-label">Цена за 1м</label>
                            <input v-model="price" id="count2" name="count" value="" placeholder="Цена за 1м" type="text" class="form-control m-b-1" title="">
                        </div>

                        <div style="color: #f39c12;" v-if="priceIsValid">
                            Цена за 1 м - {{ priceForOne }} руб.
                        </div>
                    </div>


                </div>


                <div class="modal-footer">
                    <button
                            @click="save('price')"
                            :class="!priceIsValid ? 'disabled' : ''"
                            type="button"
                            id="save_textile_price"
                            data-action=""
                            class="btn btn-warning">Обновить</button>
                </div>



            </div>
        </div>
    </div>
</template>
<script>
    //import { mapGetters } from 'vuex';

    export default {
        data() {
            return {
                price: null,
            }
        },
        computed: {
            priceForOne: function () {
                return Number(Math.round(this.price * 100) / 100)
            },
            priceIsValid: function () {
                return isFinite(this.priceForOne) && this.priceForOne !== 0;
            },
            dataPrice(n, o) {
                return this.$store.state.priceData.dataPrice
            }
        },
        watch: {
            dataPrice(n, o) {
                this.price = this.$store.state.priceData.dataPrice
            }

        },
        created() {

        },
        mounted() {

        },
        methods: {
            save(whatToSaveOption) {

                if (!this.priceIsValid) {
                    return;
                }

                let url = '/storage/textile-storage/set-price';

                axios.post(url, {
                    dataPrice: this.$store.state.priceData,
                    price: this.priceForOne,
                    whatToSaveOption: whatToSaveOption
                }).then(response => {
                    //console.log(response.data);

                    $('#' + this.$store.state.priceData.dataTextileId +
                        '_' + this.$store.state.priceData.dataColorId +
                        '_m').text(response.data.price).removeClass('textile-storage-empty');


                    let modal = $('#storagePriceModal');
                    modal.modal('hide');

                }).catch(err => {

                });

            },
        },
    }
</script>

<style scoped>
    .form-group {
        margin-bottom: 0;
    }


</style>