<template>
    <div class="modal fade" ref="textileStoragePriceModal" id="textileStoragePriceModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">

                <div class="modal-header">
                    <h4 class="modal-title" id="modal-label">{{ this.$store.state.priceData.modalTitle }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>

                <div class="modal-body">
                    <div class="m-y-1" id="modal-textile-info">
                        {{ this.$store.state.priceData.dataTextile }},
                        {{ this.$store.state.priceData.dataColor }}
                    </div>

                    <div style="display: flex;">
                        <div style="width: 30%;">
                            <label for="count1" class="control-label">Количество</label>
                            <input v-model="count" id="count1" name="count" value="" placeholder="Количество" type="text" class="form-control m-b-1" title="">
                        </div>
                        <div class="radio custom-radio">
                            <label>
                                <input v-model="measure" type="radio" name="optionsRadios1" id="radio-m" value="m">
                                м
                            </label>
                        </div>
                        <div class="radio custom-radio">
                            <label>
                                <input v-model="measure" type="radio" name="optionsRadios1" id="radio-kg" value="kg">
                                кг
                            </label>
                        </div>
                        <div v-if="measure === `kg`" style="margin-left: 20px;">
                            <label for="ratio" class="control-label">Коэффициент ({{ `1кг - ` + ratio + `м` }})</label>
                            <input v-model="ratio" @keypress="onlyForCurrency" id="ratio" name="ratio" value="" placeholder="Коэффициент" type="text" class="form-control m-b-1" title="">
                        </div>

                        <div v-if="measure === `kg`" style="margin: 30px 20px;">
                            <span v-if="quantity">~ {{ roundQuantity }}м</span>
                        </div>
                    </div>


                    <div>
                        <label for="price" class="control-label">Общая стоимость</label>
                        <input v-model="price" id="price" name="price" value="" placeholder="Общая стоимость" type="text" class="form-control m-b-1" title="">
                    </div>



                    <div style="color: #f39c12;" v-if="priceIsValid">
                        Цена за 1 м - {{ priceForOne }} руб.
                    </div>


                </div>


                <div class="modal-footer">
                    <button
                            @click="save('all')"
                            :class="!priceIsValid ? 'disabled' : ''"
                            type="button"
                            id="save_textile_all"
                            data-action=""
                            class="btn btn-success">Сохранить</button>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    //import { mapGetters } from 'vuex';

    export default {
        data() {
            return {
                count: null,
                price: null,
                ratio: null,
                measure: "m"
            }
        },
        computed: {
            quantity: function() {
                let transformedValue = this.count
                if (this.measure === "kg") {
                    transformedValue = this.count * this.ratio
                }
                return transformedValue
            },
            roundQuantity() {
                return Math.ceil(this.quantity)
            },
            priceForOne: function () {
                return Number(Math.round(this.price / this.quantity * 100) / 100)
            },
            priceIsValid: function () {
                return isFinite(this.priceForOne) && this.priceForOne !== 0;
            },
            compoundId: function () {
                return this.$store.state.priceData.dataTextileId +
                    '_' +
                    this.$store.state.priceData.dataColorId +
                    this.$store.state.priceData.dataUnit;

            },
            dataRatio: function() {
                return this.$store.state.priceData.dataRatio
            }
            // ...mapGetters([
            //     'priceData'
            // ])
        },
        watch: {
            // priceData(newValue, oldValue) {
            //     this.asd = 33;
            // },
            compoundId(n, o) {
                this.price = null;
                this.count = null;
            },
            dataRatio(n, o) {
                this.ratio = this.$store.state.priceData.dataRatio + ""
            }
        },
        created() {

        },
        mounted() {

        },
        methods: {
            save(whatToSaveOption) {
                if (!this.priceIsValid && (whatToSaveOption !== 'all')) {
                    return;
                }

                if (!this.quantity && whatToSaveOption === 'all') {
                    return;
                }

                let url = '/storage/textile-storage/set-price';

                axios.post(url, {
                    dataPrice: this.$store.state.priceData,
                    price: this.priceForOne,
                    ratio: this.ratio,
                    quantity: this.roundQuantity,
                    whatToSaveOption: whatToSaveOption
                }).then(response => {
                    //console.log(response.data);

                    if (whatToSaveOption === 'all') {
                        $('#stock_' + this.$store.state.priceData.dataTextileId +
                            '_' + this.$store.state.priceData.dataColorId).text(response.data.quantity).removeClass('textile-storage-empty');

                        $('#' + this.$store.state.priceData.dataTextileId +
                            '_' + this.$store.state.priceData.dataColorId +
                            '_m').text(response.data.price).removeClass('textile-storage-empty');

                    }


                    let modal = $('#textileStoragePriceModal');
                    modal.modal('hide');

                }).catch(err => {

                });

            },
            onlyForCurrency($event) {
                // console.log($event.keyCode); //keyCodes value
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

                // only allow number and one dot
                if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.ratio.indexOf('.') != -1)) { // 46 is dot
                    $event.preventDefault();
                }

                // restrict to 2 decimal places
                if(this.ratio!=null && this.ratio.indexOf(".")>-1 && (this.ratio.split('.')[1].length > 1)){
                    $event.preventDefault();
                }
            },
        },
    }
</script>

<style scoped>
    .form-group {
        margin-bottom: 0;
    }

    .custom-radio {
        margin: 0;
        padding-top: 30px;
        margin-left: 10px;
    }

</style>