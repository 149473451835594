<template>
    <div class="col-lg-2 col-md-3">
        <div class="form-group field-label-price_per_one has-success">
            <label for="label-price_per_one" class="control-label">Цена за 1 м.э. </label>
            <input v-model="price"
                   type="text"
                   id="label-price_per_one"
                   class="form-control"
                   aria-invalid="false">
            <div class="help-block"></div>
        </div>
        <div class="form-group">
            <button @click.prevent="savePrice" class="btn btn-success btn-xs" :class="isBusy || !priceIsValid ? 'disabled' : ''" style="width: 100%;">Сохранить</button>
        </div>
        <span v-if="priceIsValid">
            Итого: {{ totalPrice }} руб.
        </span>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                price: null,
                count: null,
                isBusy: false,
            }
        },

        props: {
            priceProp: Number,
            countProp: Number,
        },
        computed: {
            totalPrice() {
                return Number(Math.round(this.price * this.count * 100) / 100);
            },
            priceIsValid: function () {
                return isFinite(this.totalPrice) && this.totalPrice !== 0;
            },
        },
        methods: {
            savePrice() {
                if (!this.priceIsValid) {
                    return;
                }

                this.isBusy = true;
                let url = '/storage/label-storage/set-price';

                axios.post(url, {
                    price: this.price,
                }).then(response => {
                    this.isBusy = false;
                }).catch(err => {

                });
            },
        },
        created() {
            this.price = this.priceProp;
            this.count = this.countProp;
        }
    }
</script>

<style scoped>

</style>