<template>
    <div class="modal fade" ref="textileStoragePriceModal" id="textileStoragePriceModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">

                <div class="modal-header">
                    <h4 class="modal-title" id="modal-label">{{ this.$store.state.priceData.modalTitle }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>

                <div class="modal-body">
                    <div class="m-y-1" id="modal-textile-info">
                        {{ this.$store.state.priceData.furType }},
                        {{ this.$store.state.priceData.furColor }}
                    </div>
                    <input v-model="count" id="count" name="count" value="" placeholder="Количество" type="text" class="form-control m-b-1" title="">
                    <input v-model="price" id="price" name="price" value="" placeholder="Общая стоимость" type="text" class="form-control m-b-1" title="">

                    <div v-if="priceIsValid">
                        Цена за 1 шт - {{ priceForOne }} руб.
                    </div>
                </div>

                <div class="modal-footer">
                    <button
                            @click="savePrice"
                            :class="!priceIsValid ? 'disabled' : ''"
                            type="button"
                            id="save_textile_price"
                            data-action=""
                            class="btn btn-success">Сохранить цену</button>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                count: null,
                price: null,
            }
        },
        computed: {
            priceForOne: function () {
                return Number(Math.round(this.price / this.count * 100) / 100);
            },
            priceIsValid: function () {
                return isFinite(this.priceForOne) && this.priceForOne !== 0;
            },
            compoundId: function () {
                return this.$store.state.priceData.furId;
            }
        },
        watch: {
            compoundId(n, o) {
                this.price = null;
                this.count = null;
            }
        },
        methods: {
            savePrice() {
                if (!this.priceIsValid) {
                    return;
                }

                let url = '/storage/furniture-storage/set-price';

                axios.post(url, {
                    dataPrice: this.$store.state.priceData,
                    price: this.priceForOne,
                }).then(response => {
                    $('#fur-id-' + this.$store.state.priceData.furId).text(this.priceForOne);
                    $('#textileStoragePriceModal').modal('hide');
                }).catch(err => {

                });

            }
        },
    }
</script>