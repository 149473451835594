<template>
    <div>
        <div
             :class="className"
             :data-textile-id="dataTextileId"
             :data-color-id="dataColorId"
             :data-textile="dataTextile"
             :data-color="dataColor"
             :modal-title="modalTitle"
             :data-ratio="dataRatio"
             :data-price="dataPrice"
             @click="showModal"
        >
            <span :id="dataTextileId + '_' + dataColorId + '_m'" class="textile-storage-instock"
                  :class= "dataPrice === 0 ? ' textile-storage-empty' : ''">
                {{ dataPrice }}
            </span>

        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },

        props: {
            className: String,
            dataTextileId: Number,
            dataColorId: Number,
            dataTextile: String,
            dataColor: String,
            modalTitle: String,
            dataRatio: Number,
            dataPrice: Number,
        },

        methods: {
            showModal() {
                this.$store.state.priceData = { ...this.$props};

                this.$store.state.priceData.dataPrice = $('#' + this.$store.state.priceData.dataTextileId +
                    '_' + this.$store.state.priceData.dataColorId +
                    '_m')[0].textContent.trim()


                let modal = $('#storagePriceModal');
                modal.modal('show');
            },
        },
    }
</script>

<style scoped>

</style>