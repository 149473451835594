var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "a",
      {
        attrs: { href: "#", id: "fur-id-" + _vm.furId },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.showModal($event)
          }
        }
      },
      [
        _vm._v(
          "\n        " +
            _vm._s(_vm.price ? _vm.price : "(не задано)") +
            "\n    "
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }