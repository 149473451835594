var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        class: _vm.className,
        attrs: {
          "data-textile-id": _vm.dataTextileId,
          "data-color-id": _vm.dataColorId,
          "data-textile": _vm.dataTextile,
          "data-color": _vm.dataColor,
          "modal-title": _vm.modalTitle,
          "data-ratio": _vm.dataRatio,
          "data-price": _vm.dataPrice
        },
        on: { click: _vm.showModal }
      },
      [
        _c(
          "span",
          {
            staticClass: "textile-storage-instock",
            class: _vm.dataPrice === 0 ? " textile-storage-empty" : "",
            attrs: { id: _vm.dataTextileId + "_" + _vm.dataColorId + "_m" }
          },
          [_vm._v("\n            " + _vm._s(_vm.dataPrice) + "\n        ")]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }