var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "storagePriceModal",
      staticClass: "modal fade",
      attrs: {
        id: "storagePriceModal",
        tabindex: "-1",
        role: "dialog",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "h4",
              { staticClass: "modal-title", attrs: { id: "modal-label" } },
              [_vm._v(_vm._s(this.$store.state.priceData.modalTitle))]
            ),
            _vm._v(" "),
            _vm._m(0)
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "div",
              { staticClass: "m-y-1", attrs: { id: "modal-textile-info" } },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(this.$store.state.priceData.dataTextile) +
                    ",\n                    " +
                    _vm._s(this.$store.state.priceData.dataColor) +
                    "\n                "
                )
              ]
            ),
            _vm._v(" "),
            _c("div", [
              _c("div", [
                _c(
                  "label",
                  { staticClass: "control-label", attrs: { for: "count2" } },
                  [_vm._v("Цена за 1м")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.price,
                      expression: "price"
                    }
                  ],
                  staticClass: "form-control m-b-1",
                  attrs: {
                    id: "count2",
                    name: "count",
                    value: "",
                    placeholder: "Цена за 1м",
                    type: "text",
                    title: ""
                  },
                  domProps: { value: _vm.price },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.price = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _vm.priceIsValid
                ? _c("div", { staticStyle: { color: "#f39c12" } }, [
                    _vm._v(
                      "\n                        Цена за 1 м - " +
                        _vm._s(_vm.priceForOne) +
                        " руб.\n                    "
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                class: !_vm.priceIsValid ? "disabled" : "",
                attrs: {
                  type: "button",
                  id: "save_textile_price",
                  "data-action": ""
                },
                on: {
                  click: function($event) {
                    return _vm.save("price")
                  }
                }
              },
              [_vm._v("Обновить")]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }