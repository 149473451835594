<template>
    <div >
        <a @click.prevent="showModal" href="#" :id="`fur-id-` + furId">
            {{ price ? price : '(не задано)' }}
        </a>

    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },

        props: {
            furId: Number,
            price: Number,
            modalTitle: String,
            furType: String,
            furColor: String,
        },

        methods: {
            showModal() {
                this.$store.state.priceData = this.$props;
                let modal = $('#textileStoragePriceModal');
                modal.modal('show');
            },
        },
    }
</script>

<style scoped>

</style>